import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/LoginPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../pages/ForgotPasswordPage.vue"),
  },
  {
    path: "/password_recover",
    name: "password-recover",
    component: () => import("../pages/PasswordRecoverPage.vue"),
  },
  {
    path: "/not-found",
    name: "page-not-found",
    component: () => import("../pages/PageNotFound.vue"),
  },
  {
    path: "",
    component: () => import("../layouts/Authenticated.vue"),
    children: [
      {
        path: "claims/:id",
        props: true,
        component: () => import("../layouts/ClaimLayout.vue"),
        children: [
          {
            path: "",
            name: "claims.home",
            component: () => import("../pages/claims/ClaimHomePage.vue"),
            props: true,
          },
          {
            path: "policy",
            name: "claims.policy",
            component: () => import("../pages/claims/ClaimPolicyPage.vue"),
            props: true,
          },
          {
            path: "scheduling",
            name: "claims.scheduling",
            component: () => import("../pages/claims/ClaimSchedulingPage.vue"),
            props: true,
          },
          {
            path: "comments",
            name: "claims.comments",
            component: () => import("../pages/claims/ClaimCommentsPage.vue"),
            props: true,
          },
          {
            path: "documents",
            name: "claims.documents",
            component: () => import("../pages/claims/ClaimDocumentsPage.vue"),
            props: true,
          },
          {
            path: "participants/not-found",
            name: "claims.participant_not_found",
            component: () =>
              import(
                "../pages/claims/participants/ClaimParticipantNotFoundPage.vue"
              ),
            props: true,
          },
          {
            path: "participants/:participant_id",
            name: "claims.participant",
            component: () =>
              import("../pages/claims/participants/ClaimParticipantsPage.vue"),
            props: true,
          },
        ],
      },
      {
        path: "",
        component: () => import("../layouts/BaseLayout.vue"),
        children: [
          {
            path: "",
            name: "home",
            redirect: {
              name: "claims",
            },
          },
          {
            path: "claims",
            name: "claims",
            component: () => import("../pages/claims/ClaimsPage.vue"),
          },
          {
            path: "/notifications",
            name: "notifications",
            component: () => import("../pages/Notifications.vue"),
          },
          {
            path: "/info_requests",
            name: "info-requests",
            component: () =>
              import("../pages/infoRequests/InfoRequestsIndexPage.vue"),
          },
          {
            path: "profile/change-password",
            name: "profile.change-password",
            component: () =>
              import("../pages/profile/ProfileChangePasswordPage.vue"),
          },
          {
            path: "*",
            component: () => import("../pages/PageNotFound.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    component: () => import("../pages/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
