<style lang="scss" scoped>
.loader-container {
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>

<template>
  <v-app>
    <div v-if="!authChecked" class="loader-container">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="50"
          color="primary"
        ></v-progress-circular>
        <div style="width: 100%" class="mt-4">A carregar</div>
      </div>
    </div>
    <router-view v-else-if="tenantFound" />
    <div v-else class="loader-container">
      <div>
        <v-img src="/images/error-404.png" width="250"></v-img>
        <br />
        <div style="width: 100%" class="text-subtitle-2">
          Companhia não encontrada
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { RouterView } from "vue-router";
import auth from "./apis/auth";

export default {
  name: "App",

  components: {
    RouterView,
  },

  data() {
    return {
      authChecked: false,
      tenantFound: true,
    };
  },

  methods: {
    async checkAuthentication() {
      try {
        const user = await auth.getUser();
        this.$store.commit("authenticate", user.user);
        document.title = user.user.company.name;
        this.$store.commit("updateInsurer", user.user.company);
        if (user.user.company.primary_color) {
          this.$vuetify.theme.currentTheme.primary =
            user.user.company.primary_color;
        }
      } catch (error) {
        this.$store.commit("unauthenticate");
        if (error.response.status === 404) {
          this.tenantFound = false;
        }
        if (error.response.status === 401) {
          document.title = error.response.data.insurer.name;
          this.$store.commit("updateInsurer", error.response.data.insurer);
          if (error.response.data.insurer.primary_color) {
            this.$vuetify.theme.currentTheme.primary =
              error.response.data.insurer.primary_color;
          }
        }
      } finally {
        this.authChecked = true;
      }
    },
  },

  created() {
    console.log("created");
    this.checkAuthentication();
  },
};
</script>
