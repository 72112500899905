import Vue from "vue";
import Vuex from "vuex";

import attachment_types from "./attachment_types";
import notifications from "./notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authenticated: false,
    user: null,
    drawer: null,
    insurer: null,
  },

  getters: {
    drawer(state) {
      return state.drawer;
    },
  },

  mutations: {
    authenticate(state, user) {
      state.authenticated = true;
      state.user = user;
    },
    unauthenticate(state) {
      state.authenticated = false;
      state.user = null;
    },
    updateInsurer(state, insurer) {
      state.insurer = insurer;
    },
    UPDATE_DRAWER(state, payload) {
      state.drawer = payload;
    },
  },

  modules: {
    attachment_types,
    notifications,
  },
});
