import notifications from "@/apis/notifications";

const state = () => ({
  unreadCount: 0,
});

const getters = {
  getNotificationsCount(state) {
    return state.unreadCount;
  },
};

const mutations = {
  setNotifications(state, count) {
    state.unreadCount = count;
  },

  removeNotification(state) {
    if (state.unreadCount > 0) {
      state.unreadCount--;
    }
  },

  removeAll(state) {
    state.unreadCount = 0;
  },
};

const actions = {
  async refresh({ commit }) {
    const response = await notifications.totalNotifications();

    commit("setNotifications", response.unread_count);
  },

  async dismiss({ commit }, notification) {
    commit("removeNotification", notification);

    await notifications.dismiss(notification.id);
  },

  async dismissAll({ commit }) {
    commit("removeAll");

    await notifications.dismissAll();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
