import http from "./http";

async function getUser() {
  const response = await http.get("/api/user");

  return response.data;
}

async function login(form) {
  const response = await http.post("/login", form);

  return response.data;
}

async function logout() {
  const response = await http.post("/logout");

  return response;
}

async function register(form) {
  const response = await http.post("/register", form);

  return response.data;
}

async function resetPassword(form) {
  const response = await http.post("/api/forgot-password", form);

  return response.data;
}

async function changePassword(form) {
  const response = await http.post("/api/user/change-password", form);

  return response.data;
}

export default {
  login,
  getUser,
  register,
  logout,
  resetPassword,
  changePassword,
};
