import Vue from "vue";

import axios from "axios";

import router from "@/router";
import store from "@/store";

const host = window.location.hostname;
const backendUrl = process.env.VUE_APP_BACKEND_URL;
console.log(host);

const hostParts = host.split(".");
console.log(hostParts);

const subdomain = hostParts[0];

const baseURL = `${backendUrl}/companies/${subdomain}`;

const instance = axios.create({
  baseURL,
  timeout: 10000,
  headers: { "Content-Type": "application/json", accept: "application/json" },
  withCredentials: true,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.commit("unauthenticate");
    }

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error.response.status;

    if (status === 422) {
      Vue.$toast.error("Corrija o formulário");
    }

    if (status === 404) {
      Vue.$toast.error("Registro não encontrado");
    }

    if (status === 500) {
      Vue.$toast.error("Erro inesperado");
    }

    return Promise.reject(error);
  }
);

export default instance;
