import attachment_types from "@/apis/attachment_types";

const state = () => ({
  attachment_types: [],
  loaded: false,
});

const getters = {
  getAttachmentTypes(state) {
    return state.attachment_types;
  },
  getLoaded(state) {
    return state.loaded;
  },
};
const mutations = {
  setAttachmentTypes(state, payload) {
    state.attachment_types = payload;
  },
  setLoaded(state, payload) {
    state.loaded = payload;
  },
};
const actions = {
  async load({ state, commit }, payload = {}) {
    const { force } = payload;

    //Previne recarregar as categorias
    if (state.loaded && !force) {
      return;
    }

    const data = await attachment_types.index();
    commit("setAttachmentTypes", data);
    commit("setLoaded", true);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
