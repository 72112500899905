import http from "./http";

async function index(params = {}) {
  const response = await http.get("/api/notifications", { params });
  return response.data;
}

async function totalNotifications() {
  const response = await http.get("/api/notifications/count");

  return response.data;
}

async function indexDismissed() {
  const response = await http.get("/api/notifications/dismiss");

  return response.data;
}

async function dismiss(id) {
  const response = await http.post(`/api/notifications/${id}/dismiss`);

  return response.data;
}

async function dismissAll() {
  const response = await http.post(`/api/notifications/dismiss`);

  return response.data;
}

async function indexByClaim(claim_id) {
  const response = await http.get(
    `/api/notifications/claims/${claim_id}/active`
  );

  return response.data.data;
}

async function dismissByClaim(claim_id) {
  const response = await http.post(
    `/api/notifications/claims/${claim_id}/dismiss`
  );

  return response.data.data;
}

export default {
  index,
  totalNotifications,
  indexDismissed,
  dismiss,
  dismissAll,
  indexByClaim,
  dismissByClaim,
};
