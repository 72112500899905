import http from "./http";

async function index() {
  const response = await http.get("/api/attachment_types");

  return response.data.data;
}

async function indexCategories(claim_id) {
  const response = await http.get(`/api/claims/${claim_id}/categories`);

  return response.data.data;
}

export default {
  index,
  indexCategories,
};
